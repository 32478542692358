/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import classnames from "classnames"

import Header from "./header"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({ children, className, detachedHeader, maxWidth }) => {
  /* swagger-ui gives precedence to url params. The url passed as a config prop to SwaggerUI is not used
  This opens us up to XSS and so we'll remove this param from the history */
  typeof history !== "undefined" &&
    history.replaceState &&
    history.replaceState(
      null,
      "",
      location.pathname +
        location.search.replace(/[?&]url=[^&]+/, "").replace(/^&/, "?") +
        location.hash
    )

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={classnames("site", className)}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        maxWidth={maxWidth}
        detachedHeader={detachedHeader}
      />
      <main>{children}</main>
      <Footer maxWidth={maxWidth} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  detachedHeader: PropTypes.bool,
  maxWidth: PropTypes.bool,
}

Layout.defaultProps = {
  detachedHeader: false,
  maxWidth: true,
}

export default Layout
