import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Link } from "gatsby"

import "./footer.scss"

const Footer = ({ maxWidth }) => (
  <footer>
    <div className={classnames("wrapper", { "max-width": maxWidth })}>
      <nav className="links-list">
        <p>
          <a
            className="links-list__item"
            href="https://www.fireeye.com/company/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy & Cookies
          </a>
          <a
            className="links-list__item"
            href="https://www.fireeye.com/company/privacy-shield-commitment.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Shield
          </a>
          <Link
            to="/terms-of-use/"
            className="links-list__item"
            activeClassName="active"
          >
            Terms of Use
          </Link>
        </p>
      </nav>
      <p className="copyright">
        &copy; {new Date().getFullYear()} FireEye, Inc. All rights reserved.
      </p>
    </div>
  </footer>
)

Footer.propTypes = {
  maxWidth: PropTypes.bool,
}

Footer.defaultProps = {
  maxWidth: true,
}

export default Footer
