import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons"

import Collapse from "./collapse"

import logo from "../images/FireEye-Developer-Hub.svg"

import "./header.scss"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  render() {
    const { detachedHeader, maxWidth } = this.props

    return (
      <header className={classnames("site-header", { fixed: !detachedHeader })}>
        <div className={classnames("wrapper", { "max-width": maxWidth })}>
          <Link className="logo" to="/">
            <img src={logo} id="site-logo" alt="FireEye" />
          </Link>
          <div
            className={classnames("collapse-toggle", {
              active: this.state.isOpen,
            })}
            onClick={this.toggle}
          >
            <FontAwesomeIcon
              className="collapse-toggle__icon"
              icon={this.state.isOpen ? faTimes : faBars}
            />
          </div>
          <Collapse className="nav-links" isOpen={this.state.isOpen}>
            <Link
              className="btn btn--nav"
              to="/docs/about/"
              partiallyActive
              activeClassName="active"
            >
              About
            </Link>
            <div className="dropdown">
              <button className="btn--nav">Docs</button>
              <div className="dropdown-content">
                <Link
                  // className="dropdown-content"
                  to="/docs/detection-on-demand/"
                  partiallyActive
                  activeClassName="active"
                >
                  Detection on Demand
                </Link>
                <Link
                  // className="dropdown-content"
                  to="/docs/endpoint/"
                  partiallyActive
                  activeClassName="active"
                >
                  Endpoint Security
                </Link>
                <Link
                  // className="dropdown-content"
                  to="/docs/helix/"
                  partiallyActive
                  activeClassName="active"
                >
                  Helix
                </Link>
              </div>
            </div>
            <div className="dropdown">
              <button className="btn--nav">APIs</button>
              <div className="dropdown-content">
                <Link
                  to="/apis/detection-on-demand/"
                  partiallyActive
                  activeClassName="active"
                >
                  Detection on Demand
                </Link>
                <Link
                  to="/endpoint-apis/"
                  partiallyActive
                  activeClassName="active"
                >
                  Endpoint Security
                </Link>
                <Link
                  to="/apis/helix/"
                  partiallyActive
                  activeClassName="active"
                >
                  Helix
                </Link>
              </div>
            </div>
            <a
              className="btn btn--nav"
              href="https://community.fireeye.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Community
            </a>
          </Collapse>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  detachedHeader: PropTypes.bool,
  maxWidth: PropTypes.bool,
}

Header.defaultProps = {
  detachedHeader: false,
  maxWidth: true,
}

export default Header
