import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./collapse.scss"

class Collapse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isOpen,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {}
    if (props.isOpen !== state.isOpen) {
      newState.isOpen = props.isOpen
      return newState
    } else {
      return null
    }
  }

  render() {
    const { className, children } = this.props
    const { isOpen } = this.state

    return (
      <div
        className={classnames("collapse", {
          open: isOpen,
          closed: !isOpen,
        })}
      >
        <div className={classnames(className, "collapse-content")}>
          {children}
        </div>
      </div>
    )
  }
}
Collapse.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default Collapse
